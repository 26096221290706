import React from 'react'
import styled from 'styled-components'
import {
  InnerContainer,
  GreySection,
  CursiveText,
  TextArrowWrapper,
  FormIconContainer,
} from '../components/reusable/StyledComponents'
import Arrow from '../components/assets/arrow.png'
import { ContactForm } from '../components/reusable/ContactForm'
import { PregnantAnimation } from '../components/PregnantAnimation'

const WorkshopCard = styled.div`
  margin-bottom: 40px;
  width: 90%;
  max-width: 1000px;
  height: auto;
  padding: 5px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  img {
    width: 150px;
  }

  p {
    margin: 10px 26px;
  }

  @media (min-width: 750px) {
    padding: 40px;
    font-size: 24px;
    align-items: start;
  }
`

export const Workshop = () => {
  return (
    <GreySection>
      <InnerContainer>
        <WorkshopCard>
          <PregnantAnimation />
          
          <p>
            Workshop inom kvinnohälsa med fokus på träning under graviditet,
            återgång till träning efter graviditet samt bäckenbottenbesvär.
            <br />
            <br />
            Riktar sig till dig som privatperson med eller utan besvär, eller
            dig som arbetar inom frisk- eller sjukvård som önskar öka din
            kunskap inom ämnet.
          </p>
        </WorkshopCard>
        <FormIconContainer>
          <TextArrowWrapper>
            <CursiveText className='cursive-text'>
              Boka genom att mejla eller fylla i
              <br />
              formuläret!
            </CursiveText>
            <img src={Arrow} alt='arrow' />
          </TextArrowWrapper>
          <ContactForm />
        </FormIconContainer>
      </InnerContainer>
    </GreySection>
  )
}
