import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import BackgroundImg from './assets/header-background.jpg'
import { Logo } from './reusable/Logo'

const HeaderContainer = styled.div`
  height: 300px;
  width: 100vw;
  z-index: -10;
  position: relative;
  h1 {
    margin: 0;
    position: absolute;
    top: 60%;
    left: 30%;
    transform: translate(-50%, -50%);
    font-family: 'Pacifico';
    text-transform: uppercase;
    color: #0179b4;
    text-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }

  h2 {
    position: absolute;
    top: 61%;
    left: 7%;
    font-family: 'Montserrat';
    text-transform: uppercase;
    color: #0179b4;
    font-size: 18px;
    text-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }

  h3 {
    position: absolute;
    top: 69%;
    left: 7%;
    font-family: 'Montserrat';
    width: 65%;
    color: #3C3C3C;
    font-size: 12px;
    word-wrap: break-word;
    text-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }
  @media (min-width: 550px) and (max-width: 829px) {
    height: 400px;

    h1 {
      font-size: 40px;
    }

    h2 {
      font-size: 24px;
    }

    h3 {
      font-size: 16px;
      width: 65%;
    }
  }

  @media (min-width: 830px) and (max-width: 1200px) {
    height: 550px;
    h1 {
      font-size: 45px;
    }

    h2 {
      font-size: 30px;
      left: 10%;
    }

    h3 {
      font-size: 18px;
      width: 60%;
      left: 10%;
    }
  }

  @media (min-width: 1201px) {
    height: 600px;
    h1 {
      font-size: 60px;
    }

    h2 {
      font-size: 40px;
      left: 12%;
    }

    h3 {
      top: 71%;
      font-size: 20px;
      width: 60%;
      left: 12%;
    }
  }
`
const HeaderBackground = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100vw;
  position: relative;
`
const NavBar = styled.div`
  background: #0179b4;
  position: fixed;
  top: 0;
  height: 80px;
  width: 100vw;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;

  h1 {
    font-family: 'Pacifico';
    text-transform: uppercase;
    color: white;
    /* text-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px; */
  }

  @media (min-width: 500px) and (max-width: 1000px) {
    padding: 15px 30px;
    height: 100px;
    a {
      font-size: 18px;
    }
  }

  @media (min-width: 1000px) {
    padding: 15px 30px;
    height: 100px;
    a {
      font-size: 20px;
    }
  }
`
const NavContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`
const StyledLink = styled(Link)`
  text-decoration: none;
  color: white;
  &:hover {
    text-decoration: underline;
  }
`

export const Header = () => {
  return (
    <>
      <NavBar>
        <Link to='/' style={{ textDecoration: 'none' }}>
          <h1>JE</h1>
        </Link>
        <NavContainer>
          <StyledLink to='/'>Hem</StyledLink>

          <StyledLink to='/om'>Om</StyledLink>

          <StyledLink to='/boka'>Boka</StyledLink>

          <StyledLink to='/workshop'>Workshop</StyledLink>
        </NavContainer>
      </NavBar>
      <HeaderContainer>
        <HeaderBackground src={BackgroundImg} alt='Rehab och fysioterapi i Linköping' />

        <h1>JE</h1>
        <h2>Fysioterapi & Hälsa</h2>
        <h3>Fysioterapimottagning i Linköping</h3>
      </HeaderContainer>
    </>
  )
}
