import React from 'react'
import Lottie from 'react-lottie'
import animationData from './assets/pregnantwoman.json'
import styled from 'styled-components'

const AnimationContainer = styled.div`
  display: flex;
`

export const PregnantAnimation = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <AnimationContainer>
      <Lottie options={defaultOptions} height={220} width={220} />
    </AnimationContainer>
  )
}
