import React from 'react'
import styled from 'styled-components'
import { ProfileCard } from '../components/reusable/ProfileCard'
import {
  GreySection,
  SecondInnerContainer,
  ProfileCardContainer,
} from '../components/reusable/StyledComponents'

const MiddleTitle = styled.h3`
  color: #0179b4;
  margin: 0;
  align-self: start;
  font-size: 20px;

  @media (min-width: 750px) {
    font-size: 24px;
  }
`

const AboutInnerContainer = styled.div`
  width: 100%;
  padding: 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @media (min-width: 750px) {
    width: 90%;
  }
`

export const About = () => {
  return (
    <GreySection>
      <SecondInnerContainer>
        <ProfileCardContainer>
          <ProfileCard />
          <h1>Välkommen att kontakta mig eller boka en tid för dina besvär. Min mottagning hittar du på Folkungplan 1,
          <br/> 
          582 25 Linköping
          </h1> 
      
        </ProfileCardContainer>

        <AboutInnerContainer>
          <p>
            Fysioterapimottagningen för dig med såväl akuta som långvariga
            smärtbesvär eller skada. Med ambition att alltid erbjuda
            individanpassad och evidensbaserad rehabilitering för en aktiv och
            hälsosam livsstil.
          </p>
          <br />
          <br />
          <MiddleTitle>
            Spetskompetens inom kvinnohälsa och bäckenbottenbesvär.
          </MiddleTitle>
          <br />
          <br />
          <p>
            Besväras du av smärta och/eller rörelsebegränsning under graviditet?
            Önskar du hjälp med träning under graviditet eller hjälp med
            återgång till träning efter graviditet? Eller har du besvär från
            bäckenbotten exempelvis i form av läckageproblematik, tyngdkänsla
            eller smärta?
            <br />
            <br />
            Med fördjupad kunskap inom kvinnors hälsa och bäckenbottenbesvär
            erbjuds du grundlig bedömning och behandling, alltid baserat på dina
            förutsättningar och mål.
          </p>
        </AboutInnerContainer>
      </SecondInnerContainer>
    </GreySection>
  )
}
