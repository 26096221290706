import styled from 'styled-components'
import Portrait from '../assets/josefine.jpg'

const CardContainer = styled.div`
  margin: 20px 0px;
  width: 250px;
  height: 320px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  div {
    text-align: center;
  }

  p {
    margin: 0;
  }

  &:hover {
    transform: scale(1) rotate(-3deg);
  }

  @media (min-width: 1000px) {
    width: 300px;
    height: 370px;
  }
`

const ProfileImg = styled.img`
  width: 210px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;

  @media (min-width: 1000px) {
    width: 260px;
  }
`
const NameParagraph = styled.p`
  font-size: 16px !important;
`

export const ProfileCard = () => {
  return (
    <CardContainer>
      <ProfileImg src={Portrait} alt='Fysioterapeut Linköping' />
      <div>
        <NameParagraph>Josefin Eriksson</NameParagraph>
        <NameParagraph>Leg. Fysioterapeut</NameParagraph>
      </div>
    </CardContainer>
  )
}
