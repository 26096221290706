import React from 'react'
import styled from 'styled-components'
import { ContactForm } from '../components/reusable/ContactForm'
import { WhiteSection } from '../components/reusable/StyledComponents'
import { BookingAnimation } from '../components/BookingAnimation'

const BookingLink = styled.a`
  padding: 10px 15px;
  border-radius: 20px;
  text-decoration: none;
  color: #fff;
  background-color: #0179b4;
`

const BookInnerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  span {
    font-size: 15px;
    font-style: italic;
    margin: 15px;
  }

  
`

const BookingWrapper = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  @media (min-width: 900px) {
    flex-direction: row;
    justify-content: space-around;
    p {
      font-size: 20px;
    }
  }

  @media (min-width: 1300px) {
    padding: 50px;
  }
`

const InfoBox = styled.div`
/* width: 96%; */
text-align: center;
padding: 20px;
display: flex;
flex-direction: column;
align-items: center;

h1 {
  font-size: 18px;
  padding: 10px;
  width: 80%;
  
}
`

const AttributeCredit = styled.span`
font-size: 9px;
align-self: center;

@media (min-width: 750px) {
    align-self: inherit;
    margin-left: 30px;
  }
`

export const Book = () => {
  return (
    <WhiteSection>
      <BookingWrapper>
        <InfoBox>
        <BookingAnimation />
        
        <h1>Boka tid för rehabilitering eller konsultation för dina akuta eller långvariga smärtbesvär och skador.</h1>
        </InfoBox>
        <BookInnerContainer>
          <h1>Priser</h1>
          <p>Nybesök: 690 kr</p>
          <p>Återbseök: 550 kr</p>
          <BookingLink
            href='https://system.easypractice.net/book/jefysioterapi?cookie_fixed=1#choose-service'
            rel='noopener noreferrer'
            target='_blank'
            
          >
            Boka här
          </BookingLink>
          <span>Eller fyll i formuläret</span>
        </BookInnerContainer>
        <BookInnerContainer>
          <ContactForm />
        </BookInnerContainer>
      </BookingWrapper>
    </WhiteSection>
  )
}
