import { Header } from './components/Header'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import { About } from './pages/About'
import { Book } from './pages/Book'
import { Home } from './pages/Home'
import { Footer } from './components/reusable/Footer'
import { Workshop } from './pages/Workshop'

function App() {
  return (
    <>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path='/om' element={<About />} />

          <Route path='/boka' element={<Book />} />

          <Route path='/' element={<Home />} />
          <Route path='/workshop' element={<Workshop />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  )
}

export default App
